import Vue from "vue";

export default {
    actions: {
        addProduct({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                dispatch('queryPost', { url: '/positions/add_product', data: data }).then(response => {
                    if (response.data == null) {
                        reject(100)
                    }
                    else {
                        resolve(response.data);
                    }
                }).catch(() => {
                    reject(100)
                })
            })
        },

        deleteProduct({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                dispatch('queryPost', { url: '/positions/delete_product', data: data }).then(response => {
                    if (response.data == null) {
                        reject(100)
                    }
                    else {
                        resolve(response.data);
                    }
                }).catch(() => {
                    reject(100)
                })
            })
        },

        deleteKeyword({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                dispatch('queryPost', { url: '/positions/delete_keyword', data: data }).then(response => {
                    if (response.data == null) {
                        reject(100)
                    }
                    else {
                        resolve(response.data);
                    }
                }).catch(() => {
                    reject(100)
                })
            })
        },


        getProducts({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                dispatch('queryPost', { url: '/positions/get_products', data: data }).then(response => {
                    if (response.data == null) {
                        reject(100)
                    }
                    else {
                        resolve(response.data);
                    }
                }).catch(() => {
                    reject(100)
                })
            })
        },

        getPositions({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                dispatch('queryPost', { url: '/positions/getPositions', data: data }).then(response => {
                    if (response.data == null) {
                        reject(100)
                    }
                    else {
                        resolve(response.data);
                    }
                }).catch(() => {
                    reject(100)
                })
            })
        },

        getCatalogWB({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                dispatch('queryPost', { url: '/positions/wb_test', data: data }).then(response => {
                    if (response.data == null) {
                        reject(100)
                    }
                    else {
                        resolve(response.data);
                    }
                }).catch(() => {
                    reject(100)
                })
            })
        },

        findKeywords({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                dispatch('queryPost', { url: '/positions/find_keywords', data: data }).then(response => {
                    if (response.data == null) {
                        reject(100)
                    }
                    else {
                        resolve(response.data);
                    }
                }).catch(() => {
                    reject(100)
                })
            })
        },
    },
    mutations: {
    },
    state: {
    },
    getters: {
    },
}
