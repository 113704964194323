import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ReviewsLikes from '@/store/modules/reviewsLikes'
import Api from './api'
import User from './modules/user'
import Product from './modules/product'
import Pickpoints from './modules/pickpoints'
import Orders from './modules/orders'
import Deliverys from './modules/deliverys'
import Payments from './modules/payments'
import Transactions from './modules/transactions'
import Favorites from './modules/favorites'
import Reviews from './modules/reviews'
import Positions from './modules/positions'
import Basket from './modules/basket'
import Questions from './modules/questions'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    axios: axios.create({
      timeout: 60000,
      baseURL: 'https://gateway.ozprod.ru/',
      headers: {
        'Content-Type': 'application/json',
      },
      maxContentLength: 1073741824,
      maxBodyLength: 1073741824,
    }),
  },
  modules: {
    Api,
    User,
    Product,
    Pickpoints,
    Orders,
    Deliverys,
    Payments,
    Transactions,
    Favorites,
    Reviews,
    Positions,
    Basket,
    ReviewsLikes,
    Questions,
  },
})
