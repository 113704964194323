export const ADMIN_EMAIL = 'a1@ozprod.ru'
export const ADMIN_PASSWORD = 'Hgd7123bvc'
export const ADMIN_LOCAL_STORAGE_KEY = 'admin-email'

export const TABLE_ITEMS_PER_PAGE_OPTIONS = [15, 30, 50]

export const StatusChip = {
  NEW: {
    name: 'Создан',
    color: '#808080',
  },
  PENDING: {
    name: 'В процессе',
    color: '#F7CB73',
  },
  CONFIRMED: {
    name: 'Подтвержден',
    color: '#56ca00',
  },
  REJECTED: {
    name: 'Отменен',
    color: '#FF6347FF',
  },
  AUTHORIZED: {
    name: 'Подтвержден',
    color: '#56ca00',
  },
  REFUNDED: {
    name: 'Возврат',
    color: '#FFA500FF',
  },
  COMPLETED: {
    name: 'Завершен',
    color: '#56ca00',
  },
}

export enum SnackbarColor {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}
