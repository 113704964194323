<template>
  <v-snackbar v-model="isActive" :color="color" :multi-line="true" :timeout="timeout">
    {{ text }}

    <template #action>
      <v-btn color="white" icon small @click="isActive = false">
        <v-icon>{{ mdiClose }}</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
  data() {
    return {
      isActive: false,
      text: '',
      color: '',
      mdiClose,
      timeout: 5000,
    }
  },
  mounted() {
    this.$root.$on('notification', (text, color) => {
      this.isActive = true
      this.text = text
      this.color = color
    })
  },
}
</script>
