import Vue from "vue";

export default {
	"actions": {
		getPickpointInfo({ commit, dispatch }, data) {
			return new Promise((resolve, reject) => {
				dispatch("queryPost", { "url": "/pickpoints/info", "data": { "link": data[0], "sku": data[1] } }).then(response => {
					if (response.data == null) {
						reject(100);
					} else {
						resolve(response.data);
					}
				}).catch(() => {
					reject(100);
				});
			});
		},

		searchPickpoint({ commit, dispatch }, search) {
			return new Promise((resolve, reject) => {
				dispatch("queryPost", { "url": "/pickpoints/search", "data": { "text": search } }).then(response => {
					if (response.data == null) {
						reject(100);
					} else {
						resolve(response.data);
					}
				}).catch(() => {
					reject(100);
				});
			});
		}
	},
	"mutations": {
	},
	"state": {
	},
	"getters": {
	}
};
