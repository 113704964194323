import Vue from "vue";

export default {
    actions: {
        createPayment({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                dispatch('queryPost', { url: 'payment', data: data }).then(response => {
                    if(response.data == null){
                        reject(100)
                    }
                    else{
                        resolve(response.data);
                    }
                }).catch(() => {
                    reject(100)
                })
            })
        },

        buyTariff({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                dispatch('queryPost', { url: 'payment/buy_tariff', data: data }).then(response => {
                    if(response.data == null){
                        reject(100)
                    }
                    else{
                        resolve(response.data);
                    }
                }).catch(() => {
                    reject(100)
                })
            })
        },

        buyProvidersFile({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                dispatch('queryPost', { url: 'payment/buy_providers'}).then(response => {
                    if(response.data == null){
                        reject(100)
                    }
                    else{
                        resolve(response.data);
                    }
                }).catch(() => {
                    reject(100)
                })
            })
        },

        getGift({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                dispatch('queryPost', { url: 'payment/gift'}).then(response => {
                    if(response.data == null){
                        reject(100)
                    }
                    else{
                        resolve(response.data);
                    }
                }).catch(() => {
                    reject(100)
                })
            })
        },
    },
    mutations: {
    },
    state: {
    },
    getters: {
    },
}
