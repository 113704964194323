import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

import Vue from 'vue'
import VueYandexMetrika from 'vue-yandex-metrika'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router/index'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

Vue.config.productionTip = false

new Vue({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')

Vue.use(VueYandexMetrika, {
  id: 86018699,
  env: 'production',
})
