<template>
  <v-navigation-drawer
    :right="$vuetify.rtl"
    :value="isDrawerOpen"
    app
    class="app-navigation-menu"
    floating
    width="310"
    @input="(val) => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="nav-content">
      <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
        <router-link class="d-flex align-center text-decoration-none" to="/">
          <v-img
            :src="require('@/assets/images/logos/OZONPRODLOGO_256.png')"
            class="me-2"
            max-height="35px"
            max-width="35px"
          ></v-img>
          <v-slide-x-transition>
            <h2 class="app-title text--primary">OZPROD</h2>
          </v-slide-x-transition>
        </router-link>
      </div>

      <!-- Navigation Items -->
      <v-list class="vertical-nav-menu-items pr-5" expand shaped>
        <v-col>
          <v-card outlined>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-card-text v-bind="attrs" v-on="on">
                  <span> Баланс руб: </span>
                  <v-skeleton-loader v-if="!loaded" type="text"></v-skeleton-loader>
                  <h3 v-else>{{ user.balance.rub }}₽</h3>
                </v-card-text>
              </template>
              <span
                >Доступный баланс для<br />
                оплаты услуг на сервисе</span
              >
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-card-text v-bind="attrs" v-on="on">
                  <span> Баланс выкупов:</span>
                  <v-skeleton-loader v-if="!loaded" type="text"></v-skeleton-loader>
                  <h3 v-else>{{ user.balance.orders }}шт</h3>
                </v-card-text>
              </template>
              <span
                >Доступный остаток выкупов<br />
                для оплаты услуги</span
              >
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-card-text v-bind="attrs" v-on="on">
                  <span> Баланс отзывов: </span>
                  <v-skeleton-loader v-if="!loaded" type="text"></v-skeleton-loader>
                  <h3 v-else>{{ user.balance.reviews }}шт</h3>
                </v-card-text>
              </template>
              <span
                >Доступный остаток отзывов<br />
                для оплаты услуги</span
              >
            </v-tooltip>

            <v-card-text>
              <v-btn block color="primary" @click="openPayment"> Пополнить</v-btn>
            </v-card-text>
            <!--<v-card-text>
              <div class="grad center borderElement">
                <span class="text1"> Успейте воспользоваться. </span>
                </br>
                <span class="text2"> Бонус к пополнению 20% до 27 января! </span>
              </div>
            </v-card-text>-->
          </v-card>
        </v-col>

        <!-- <nav-menu-link
          title="Настройки профиля"
          :to="{ name: 'auth' }"
          :icon="icons.mdiAccountCogOutline"
        ></nav-menu-link> -->
        <nav-menu-link
          :icon="icons.mdiBookInformationVariant"
          :to="{ name: 'faq' }"
          title="Как пользоваться"
        ></nav-menu-link>
        <div>
          <a class="text-decoration-none" href="https://wbprod.ru" target="_blank">
            <v-list-item>
              <v-list-item-icon>
                <v-img :src="require('@/assets/images/misc/wbprod_ico.png')" max-height="24px" max-width="24px"></v-img>
              </v-list-item-icon>
              <v-list-item-title>Выкупы WB</v-list-item-title>
            </v-list-item>
          </a>
        </div>
        <nav-menu-link
          v-if="isAdmin"
          :icon="icons.mdiCardAccountMailOutline"
          :to="{ name: 'service_support' }"
          title="Сервис"
        ></nav-menu-link>
        <nav-menu-link
          v-if="isAdmin"
          :icon="icons.mdiCardAccountMailOutline"
          :to="{ name: 'service_support_promo' }"
          title="Промокоды"
        ></nav-menu-link>
        <nav-menu-section-title title="Аналитика"></nav-menu-section-title>
        <nav-menu-link
          :icon="icons.mdiTrendingUp"
          :is-on-tech="true"
          :to="{ name: 'positions' }"
          title="Позиции"
        ></nav-menu-link>

        <nav-menu-section-title title="Заказы"></nav-menu-section-title>
        <nav-menu-link
          :icon="icons.mdiCreditCardOutline"
          :to="{ name: 'orders', query: { tab: 'actived', page: '1' } }"
          :is-on-tech='false'
          title="Выкупы"
        ></nav-menu-link>
        <nav-menu-link
          :icon="icons.mdiCreditCardOutline"
          :to="{ name: 'mass_create' }"
          :is-on-tech='false'
          title="Массовый выкуп"
        ></nav-menu-link>

        <nav-menu-link
          :badge="user.notifications.delivery['can_picked']"
          :icon="icons.mdiTruckDelivery"
          :to="{ name: 'deliverys', query: { tab: 'in_delivery' } }"
          title="Доставки"
        ></nav-menu-link>
        <nav-menu-link
          :badge="user.notifications.reviews['wait']"
          :icon="icons.mdiClipboardEditOutline"
          :to="{ name: 'reviews', query: { tab: 'wait', search: '' } }"
          :is-on-tech='false'
          title="Отзывы"
        ></nav-menu-link>
        <div>
          <a class="text-decoration-none" href="https://t.me/Ehisodik" target="_blank">
            <v-list-item>
              <v-list-item-icon>
                <v-icon class="mx-auto">
                  {{ icons.mdiKeyVariant }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Выкупы под ключ</v-list-item-title>
            </v-list-item>
          </a>
        </div>

        <nav-menu-section-title title="Лайки"></nav-menu-section-title>
        <nav-menu-link
          :icon="icons.mdiHeartBox"
          :to="{ name: 'favorite_product' }"
          title="Товар в избранное"
          :is-on-tech="true"
        ></nav-menu-link>
        <nav-menu-link
          :icon="icons.mdiCartOutline"
          :to="{ name: 'add-to-basket' }"
          title="Добавление в корзину"
          :is-on-tech="true"
        ></nav-menu-link>
        <nav-menu-link :is-on-tech="true" :icon="icons.mdiHeartBox" :to="{ name: 'review_like' }" title="Лайки на отзывы"></nav-menu-link>
        <nav-menu-link :is-on-tech='true'  :icon="icons.mdiHelpBox" :to="{ name: 'questions' }" title="Вопросы"></nav-menu-link>
        <!-- <nav-menu-link
          title="Добавление в"
          title2="корзину"

          :to="{ name: 'favorite_basket' }"
          :icon="icons.mdiHeartBox"
        ></nav-menu-link> -->

        <nav-menu-section-title title="Финансы"></nav-menu-section-title>

        <nav-menu-link :icon="icons.mdiTag" :to="{ name: 'tariffs' }" title="Тарифы"></nav-menu-link>
        <nav-menu-link :icon="icons.mdiCashCheck" :to="{ name: 'finances' }" title="Финансы"></nav-menu-link>
        <nav-menu-link
          :icon="icons.mdiCashMultiple"
          :to="{ name: 'partner' }"
          title="Партнерская"
          title2="система"
        ></nav-menu-link>

        <nav-menu-link :icon="icons.mdiDatabase" :to="{ name: 'providers' }" title="База поставщиков"></nav-menu-link>
        <nav-menu-link
          :icon="icons.mdiLogout"
          class="cursor-pointer"
          title="Выйти из аккаунта"
          @click.native="$root.$emit('logout')"
        />

        <v-card class="ml-3" color="primary" @click="openPage('https://t.me/ozprodtp')">
          <div class="d-flex align-center ma-4 justify-space-between">
            <v-avatar class="mr-3" color="white">
              <v-img
                :src="require('@/assets/images/logos/OZONPRODLOGO_256.png')"
                max-height="35px"
                max-width="35px"
              ></v-img>
            </v-avatar>
            <div class="text-right my-2">
              <p class="subtitle-2 ma-0 pa-0 white--text">OZPROD Channel</p>
              <p class="caption ma-0 pa-0 white--text">Наш телеграм канал с новостями</p>
            </div>
          </div>
        </v-card>
        <!--      { title: 'Выйти из аккаунта', icon: 'Logout', good: true, click() { this.$root.$emit('logout') }, },-->
        <!-- <nav-menu-link title="Бренд в избранное" :to="{ name: 'orders' }" :icon="icons.mdiHeartCircle"></nav-menu-link> -->
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import {
  mdiAccountCogOutline,
  mdiAlertCircle,
  mdiAlphaTBoxOutline,
  mdiBookInformationVariant,
  mdiCartOutline,
  mdiCashCheck,
  mdiCashMultiple,
  mdiClipboardEditOutline,
  mdiCreditCardOutline,
  mdiDatabase,
  mdiEyeOutline,
  mdiFileOutline,
  mdiFormSelect,
  mdiHeartBox,
  mdiHeartCircle,
  mdiHelpBox,
  mdiHomeOutline,
  mdiKeyVariant,
  mdiLogout,
  mdiTable,
  mdiTag,
  mdiTrendingUp,
  mdiTruckDelivery,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import { ADMIN_EMAIL, ADMIN_LOCAL_STORAGE_KEY } from '@/utils/const'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuLink,
  },
  methods: {
    openPayment() {
      this.$root.$emit('payment')
    },
    openPage(url) {
      window.open(url, '_blank')
    },
  },
  computed: {
    ...mapGetters(['user', 'loaded']),

    isAdmin() {
      return this.user.email === ADMIN_EMAIL || !!localStorage.getItem(ADMIN_LOCAL_STORAGE_KEY)
    },
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      icons: {
        mdiCartOutline,
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiTruckDelivery,
        mdiLogout,
        mdiTag,
        mdiClipboardEditOutline,
        mdiCashCheck,
        mdiHelpBox,
        mdiHeartBox,
        mdiHeartCircle,
        mdiDatabase,
        mdiCashMultiple,
        mdiKeyVariant,
        mdiBookInformationVariant,
        mdiAlertCircle,
        mdiTrendingUp,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.nav-content {
  background-color: var(--v-nav-base) !important;
  margin: 10px 0 10px 10px;
  border-radius: 10px;
}

.vertical-nav-menu-items {
  overflow-y: auto;
  border-radius: 10px !important;
  height: calc(100vh - 83px);

  &::-webkit-scrollbar-track {
    background: var(--v-nav-base);
  }
}

.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  ::-webkit-scrollbar {
    //display: none;
  }

  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

.center {
  text-align: center;
}

.text1 {
  color: yellow;
  font-size: 100%;
  font-weight: bold;
}

.text2 {
  color: white;
  font-size: 90%;
  font-weight: bold;
}

.grad {
  background: rgb(178, 37, 182);
  background: linear-gradient(90deg, rgba(178, 37, 182, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(37, 88, 152, 1) 100%);
}

.borderElement {
  border-radius: 6px;
}
</style>
<style lang="scss">
.v-navigation-drawer__content {
  overflow-y: hidden !important;
}
</style>
