export default {
  actions: {
    create_promo({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', {
          url: 'create_promo',
          data,
        })
          .then((response) => {
            resolve(response.data)
          })
          .catch(() => {
            reject(100)
          })
      })
    },
    getUser({ commit, dispatch }, update) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', { url: 'getUser' })
          .then((response) => {
            commit('updateUser', response.data)
            resolve(response.data)
          })
          .catch(() => {
            reject(100)
          })
      })
    },
    get_user_token({ commit, dispatch }, email) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', {
          url: 'user_token',
          data: { email },
        })
          .then((response) => {
            commit('updateUser', response.data)
            resolve(response.data)
          })
          .catch(() => {
            reject(100)
          })
      })
    },
    auth({ commit, dispatch }, authData) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', {
          url: 'auth',
          data: authData,
        })
          .then((response) => {
            commit('updateUser', response.data)
            resolve(response.data)
          })
          .catch(() => {
            reject(100)
          })
      })
    },

    activatePromo({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', {
          url: 'promocode',
          data,
        })
          .then((response) => {
            resolve(response.data)
          })
          .catch(() => {
            reject(100)
          })
      })
    },

    saveInfo({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', {
          url: 'save_info',
          data,
        })
          .then((response) => {
            resolve(response.data)
          })
          .catch(() => {
            reject(100)
          })
      })
    },

    changePassword({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', {
          url: 'change_password',
          data,
        })
          .then((response) => {
            resolve(response.data)
          })
          .catch(() => {
            reject(100)
          })
      })
    },
    updateUserAutoRenewals({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', {
          url: 'auto_renewals',
          data,
        })
          .then((response) => {
            resolve(response.data)
          })
          .catch(() => {
            reject(100)
          })
      })
    },
    register({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', {
          url: 'register',
          data,
        })
          .then((response) => {
            resolve(response.data)
          })
          .catch(() => {
            reject(100)
          })
      })
    },

    logout({ commit, dispatch }) {
      localStorage.removeItem('token')
      localStorage.removeItem('admin-email')
      commit('logout')
    },
  },
  mutations: {
    updateUser(state, user) {
      state.user = user
      state.loaded = true
    },
    logout(state) {
      state.user = null
      state.loaded = false
    },
  },
  state: {
    loaded: false,
    user: {
      special_sale_like: null,
      balance: {
        rub: 0,
        orders: 0,
        reviews: 0,
      },
      pricing: {
        add_to_basket: 2,
        order: 89,
        reviews: 89,
        product_like: 9,
        reviews_like: 7,
      },
      notifications: {
        delivery: {
          can_picked: 0,
          in_delivery: 0,
          picked: 0,
          rejected: 0,
        },
        reviews: {
          wait: 0,
          in_moderation: 0,
          published: 0,
          pending_publish: 0,
          ozon_canceled: 0,
        },
      },

      user_info: {
        firstName: '',
        lastName: '',
        telegram: '',
      },
      providers: false,
      email: '',
      auto_renewals: false,
    },
  },
  getters: {
    user(state) {
      return state.user
    },
    loaded(state) {
      return state.loaded
    },
  },
}
