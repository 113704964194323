import Vue from "vue";
import fileDownload from "js-file-download";

export default {
	"actions": {
		getDeliverys({ commit, dispatch }, data) {
			return new Promise((resolve, reject) => {
				dispatch("queryPost", { "url": "/deliverys", "data": { "type": data.type, "page": data.page, "query": data.query } }).then(response => {
					if (response.data == null) {
						reject(100);
					} else {
						resolve(response.data);
					}
				}).catch(() => {
					reject(100);
				});
			});
		},
		extendDelivery({ commit, dispatch }, data) {
			return new Promise((resolve, reject) => {
				dispatch("queryPost", { "url": "/deliverys/extend_delivery", "data": { "order_id": data } }).then(response => {
					if (response.data == null) {
						reject(100);
					} else {
						resolve(response.data);
					}
				}).catch(() => {
					reject(100);
				});
			});
		},

		downloadExcel({ commit, dispatch }, data) {
			return new Promise((resolve, reject) => {
				dispatch("queryPost", { "url": "/deliverys/excel", "data": { "types": data.types, "page": data.page }, "params": { "responseType": "blob" } }).then(response => {
					if (response.data == null) {
						reject(100);
					} else {
						fileDownload(response.data, "Доставки.xlsx");
						resolve();
					}
				}).catch(() => {
					reject(100);
				});
			});
		},
		downloadCheckPdf({ commit, dispatch }, data) {
			return new Promise((resolve, reject) => {
				dispatch("queryPost", { "url": "/deliverys/get_pdf_check", "data": { "order_id": data.order_id }, "params": { "responseType": "blob" } }).then(response => {
					if (response.data == null) {
						reject(100);
					} else {
						fileDownload(response.data, `${data.order_id}.pdf`);
						resolve();
					}
				}).catch(() => {
					reject(100);
				});
			});
		}
	},
	"mutations": {
	},
	"state": {
	},
	"getters": {
	}
};
