require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#9155FD',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        footer: '#f4f5fa',
        scrollbar: '#8A8D93',
        background: '#f4f5fa',
        nav: '#ebebf1',
      },
      dark: {
        primary: '#9155FD',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        background: '#28243d',
        footer: '#9155FD',
        card: '#312d4b',
        nav: '#302D4B',
        scrollbar: '#9155FD',
      },
    },
  },
}
