export default {
  actions: {
    getQuestionsTasks({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('queryGet', {
          url: '/questions/get_tasks',
        })
          .then((response) => {
            if (response.data == null) {
              reject(100)
            } else {
              resolve(response.data)
            }
          })
          .catch(() => {
            reject(100)
          })
      })
    },
    createQuestionsReviewTask({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', {
          url: '/questions/create_task',
          data,
        })
          .then((response) => {
            if (response.data == null) {
              reject(100)
            } else {
              resolve(response.data)
            }
          })
          .catch(() => {
            reject(100)
          })
      })
    },
  },
  mutations: {},
  state: {},
  getters: {},
}
