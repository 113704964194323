<template>
  <v-app>
    <v-main>
      <div class="app-content-container boxed-container">
        <slot></slot>
        <notification />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Notification from './dialogs/Notification.vue'

export default {
  components: {
    Notification,
  },
}
</script>

<style></style>
