export default {
  actions: {
    createProductTask({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', {
          url: 'favorite/add_product_task',
          data,
        })
          .then((response) => {
            if (response.data == null) {
              reject(100)
            } else {
              resolve(response.data)
            }
          })
          .catch(() => {
            reject(100)
          })
      })
    },
    getAddToBasketTasks({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', {
          url: 'favorite/add_basket_tasks',
        })
          .then((response) => {
            if (response.data == null) {
              reject(100)
            } else {
              resolve(response.data)
            }
          })
          .catch(() => {
            reject(100)
          })
      })
    },

    createAddToBasketTask({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', {
          url: 'favorite/add_basket_task_create',
          data,
        })
          .then((response) => {
            if (response.data == null) {
              reject(100)
            } else {
              resolve(response.data)
            }
          })
          .catch(() => {
            reject(100)
          })
      })
    },

    getProductsLikesTasks({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', {
          url: 'favorite/product_tasks',
          data,
        })
          .then((response) => {
            if (response.data == null) {
              reject(100)
            } else {
              resolve(response.data)
            }
          })
          .catch(() => {
            reject(100)
          })
      })
    },
  },
  mutations: {},
  state: {},
  getters: {},
}
