<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title class="text-h5">Внимание</v-card-title>

      <v-card-text>Данной услугой можно воспользоваться после пополнения баланса.</v-card-text>
      <v-card-text>Ваш тестовый баланс можно использовать только для оплаты выкупа или отзыва.</v-card-text>

      <v-divider></v-divider>

      <v-col>
        <v-row>
          <v-col cols="4">
            <v-btn color="primary" width="100%" outlined large @click="dialog = false">Закрыть</v-btn>
          </v-col>
          <v-col>
            <v-btn color="primary" width="100%" large @click="openPayment">Пополнить</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      dialog: false,
    }
  },
  mounted() {
    this.$root.$on('can_use_test_balance', () => {
      this.dialog = true
    })
  },
  methods: {
    openPayment() {
      this.dialog = false
      this.$root.$emit('payment')
    },
  },
}
</script>
