










import Vue from 'vue'
import { mdiClose } from '@mdi/js'

const LS_KEY = '27-dec-2023'

export default Vue.extend({
  name: 'PromotionDialog',

  data() {
    return {
      mdiClose,
      modelValue: false,
      canCloseDialog: false,
    }
  },

  mounted() {
    if (localStorage.getItem(LS_KEY) === null) {
      setTimeout(() => {
        this.modelValue = true
      }, 5000)

      setTimeout(() => {
        this.canCloseDialog = true
      }, 8000)
    }
  },

  watch: {
    modelValue() {
      if (this.modelValue === false) {
        localStorage.setItem(LS_KEY, 'true')
      }
    },
  },

  methods: {
    onClick() {
      this.$root.$emit('payment')
      this.modelValue = false

      // this.$router.push(RouteName.AUTO_REPLIES)

      // window.open('https://quick24.ru/conf#price ', '_blank')
    },
  },
})
