async function handleResponse(response) {
  if (response.data === null) {
    throw new Error('Data is null')
  }

  return response.data
}

export default {
  actions: {
    async createOrder({ commit, dispatch }, order) {
      try {
        const response = await dispatch('queryPost', {
          url: '/orders/create',
          data: { order },
        })

        return await handleResponse(response)
      } catch (error) {
        throw new Error('Something went wrong')
      }
    },

    async createMassOrder({ commit, dispatch }, orders) {
      try {
        const response = await dispatch('queryPost', {
          url: '/orders/mass_create',
          data: { orders },
        })

        return await handleResponse(response)
      } catch (error) {
        throw new Error('Something went wrong')
      }
    },

    async getOrders({ commit, dispatch }, { type, query }) {
      try {
        const response = await dispatch('queryPost', {
          url: `/orders?page=${query.page}`,
          data: { type },
        })

        return await handleResponse(response)
      } catch (error) {
        throw new Error('Something went wrong')
      }
    },

    async getOrdersByGroup({ commit, dispatch }, groupId) {
      try {
        const response = await dispatch('queryPost', {
          url: '/orders/by_group',
          data: { id: groupId },
        })

        return await handleResponse(response)
      } catch (error) {
        throw new Error('Something went wrong')
      }
    },

    async getOrderById({ commit, dispatch }, id) {
      try {
        const response = await dispatch('queryPost', {
          url: '/order/by_id',
          data: { id },
        })

        return await handleResponse(response)
      } catch (error) {
        throw new Error('Something went wrong')
      }
    },

    async paidOrder({ commit, dispatch }, data) {
      try {
        const response = await dispatch('queryPost', {
          url: '/order/paid',
          data,
        })

        return await handleResponse(response)
      } catch (error) {
        throw new Error('Something went wrong')
      }
    },

    async deleteUnpaid({ commit, dispatch }, groupId) {
      try {
        const response = await dispatch('queryPost', {
          url: '/order/delete_unpaid',
          data: { groupId },
        })

        return await handleResponse(response)
      } catch (error) {
        throw new Error('Something went wrong')
      }
    },

    async deleteOrder({ commit, dispatch }, id) {
      try {
        const response = await dispatch('queryPost', {
          url: '/order/delete',
          data: { id },
        })

        return await handleResponse(response)
      } catch (error) {
        throw new Error('Something went wrong')
      }
    },

    async cloneOrder({ commit, dispatch }, groupId) {
      try {
        const response = await dispatch('queryPost', {
          url: '/order/clone',
          data: { groupId },
        })

        return await handleResponse(response)
      } catch (error) {
        throw new Error('Something went wrong')
      }
    },

    async uploadExcel({ commit, dispatch }, file) {
      try {
        const response = await dispatch('uploadFile', {
          url: '/order/mass_excel',
          file,
        })

        return await handleResponse(response)
      } catch (error) {
        throw new Error('Something went wrong')
      }
    },

    async cancelOrder({ commit, dispatch }, orderId) {
      try {
        const response = await dispatch('queryPost', {
          url: '/order/cancel',
          data: { orderId },
        })

        return await handleResponse(response)
      } catch (error) {
        throw new Error('Something went wrong')
      }
    },
  },
  mutations: {},
  state: {},
  getters: {},
}
