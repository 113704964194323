export default {
  actions: {
    findProduct({ commit, dispatch }, link) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', {
          url: 'product/find',
          data: { link },
        })
          .then((response) => {
            if (response.data == null) {
              reject(100)
            } else {
              resolve(response.data)
            }
          })
          .catch(() => {
            reject(100)
          })
      })
    },
    getProductInfo({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', {
          url: 'product/get_product_info_from_pvz',
          data,
        })
          .then((response) => {
            if (response.data == null) {
              reject(100)
            } else {
              resolve(response.data)
            }
          })
          .catch(() => {
            reject(100)
          })
      })
    },
  },
}
