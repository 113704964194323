<template>
  <v-dialog v-model="payment_dialog" width="400">
    <v-card>
      <v-col v-if="type === 'payment'" class="pa-6">
        <p class="text-h5">Пополнить баланс</p>
        <div class="px-5 py-4 text-400-14 error-card">
          <span class="error--text">ВНИМАНИЕ!</span> Вы пополняете баланс <span class="error--text">ТОЛЬКО</span>
          для оплаты услуг сервиса. За товар оплата будет идти отдельно напрямую с указанного вами кошелька Юмани при
          создании заказа в разделе «Выкупы».
          <br />
          <br />
          В случае внесения на баланс суммы и за услуги, и за товар возврат средств не предусмотрен.
        </div>
        <p class="mt-12">Сумма пополнения:</p>
        <v-text-field
          v-model="paymentPrice"
          class="ma-0 pa-0 font-weight-black"
          hide-details
          outlined
          single-line
          style="font-size: 1.5em"
          suffix="₽"
        ></v-text-field>
        <!--<v-card-text class="pa-0 font-weight-black grad borderElement" style="margin-top: 2%">
          <table class="text">
          <tr>
            <div style="margin-top: 2%; margin-left: 4%;">
              <span class="text1"> Бонус к пополнению </span>
              </br>
              <span class="text2"> +20% при пополнении </span>
            </div>
            <td class="rightcol">
              <div style="margin-right: 4%; margin-top: 7%" >
                <span class="text3" > Вы получите: {{Number(paymentPrice) + (Number(paymentPrice) / 100 * 20)  }}₽ </span>
              </div>
            </td>
          </tr>
          </table>
          </v-card-text>-->
        <div class="d-flex mt-4 justify-space-between">
          <v-chip style="height: 35px" @click="paymentPrice = 1000"> 1000 ₽</v-chip>
          <v-chip style="height: 35px" @click="paymentPrice = 10000"> 10 000 ₽</v-chip>
          <v-chip style="height: 35px" @click="paymentPrice = 50000"> 50 000 ₽</v-chip>
          <v-chip style="height: 35px" @click="paymentPrice = 100000"> 100 000 ₽</v-chip>
        </div>

        <v-row class="mt-12">
          <v-col v-if="paymentLink == null">
            <v-btn block outlined x-large @click="payment_dialog = false"> Закрыть</v-btn>
          </v-col>
          <v-col v-if="paymentLink == null">
            <v-btn :loading="loading" block color="primary" x-large @click="_createPayment"> Пополнить </v-btn>
          </v-col>
          <v-col v-if="paymentLink != null">
            <v-btn block color="primary" x-large @click="openPayment"> Перейти к оплате</v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="type == 'reserved'" class="pa-6">
        <p class="text-h5">Пополнить баланс</p>
        <p class="subtitle-2">
          Пополнение зарезервировано и будет проведено в течение нескольких минут. В отдельных случаях проведение
          транзакции может длиться до 2х часов.
        </p>

        <v-row class="mt-5">
          <v-col v-if="paymentLink == null">
            <v-btn block outlined x-large @click="payment_dialog = false"> Закрыть</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      type: 'payment',
      payment_dialog: false,
      paymentPrice: 1000,
      loading: false,
      paymentLink: null,
    }
  },
  mounted() {
    this.$root.$on('payment', () => {
      this.paymentLink = null
      this.type = 'payment'
      this.payment_dialog = true
    })
  },
  methods: {
    ...mapActions(['createPayment']),
    async _createPayment() {
      this.loading = true
      const res = await this.createPayment({
        type: 'balance',
        amount: this.paymentPrice,
      })
      this.loading = false
      if (res != null) {
        this.paymentLink = res
      }
    },
    openPayment() {
      // Метрика для оплаты
      const input = document.createElement('input')
      input.value = 'Заказать'
      input.type = 'button'
      input.onclick = () => {
        window.ym(86976211, 'reachGoal', 'platech', {
          order_price: this.paymentPrice,
          currency: 'RUB',
        })
      }

      input.click()

      this.$metrika.reachGoal('oplataOzprod')
      window.open(this.paymentLink, '_blank').focus()
      this.paymentLink = null
      this.type = 'reserved'
    },
  },
}
</script>
<style scoped>
.error-card {
  border: 3px solid tomato;
  border-radius: 16px;
}
</style>
<style>
table.text {
  width: 100%; /* Ширина таблицы */
  border-spacing: 0; /* Расстояние между ячейками */
}

table.text td {
  width: 50%; /* Ширина ячеек */
  vertical-align: top; /* Выравнивание по верхнему краю */
}

td.rightcol {
  /* Правая ячейка */
  text-align: right; /* Выравнивание по правому краю */
}

.center {
  text-align: center;
}

.text1 {
  color: yellow;
  font-size: 100%; /* Размер шрифта в процентах */
  font-weight: bold;
}

.text3 {
  color: white;
  font-size: 100%; /* Размер шрифта в процентах */
  font-weight: bold;
}

.text2 {
  color: white;
  font-size: 90%; /* Размер шрифта в процентах */
  font-weight: bold;
}

.grad {
  /* Градиент */
  background: rgb(178, 37, 182);
  background: linear-gradient(90deg, rgba(178, 37, 182, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(37, 88, 152, 1) 100%);
}

.borderElement {
  border-radius: 6px;
}
</style>
