










import Vue from 'vue'
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'

export default Vue.extend({
  data() {
    return {
      icons: {
        mdiWeatherSunny,
        mdiWeatherNight,
      },
    }
  },
  created() {
    this.$vuetify.theme.dark = localStorage.getItem('is-theme-dark') ?? false
  },
  methods: {
    handleThemeClick() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark

      localStorage.setItem('is-theme-dark', this.$vuetify.theme.dark ? this.$vuetify.theme.dark : '')
    },
  },
})
