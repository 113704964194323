<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card>
      <v-card-title class="text-h5"> {{ title }}</v-card-title>

      <v-card-text> {{ message }}</v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click.native="cancel"> Закрыть</v-btn>
        <v-btn :loading="isLoading" color="primary" outlined text @click.native="agree"> OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      message: null,
      title: null,
      isLoading: false,
      options: {
        color: 'grey lighten-3',
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
    }
  },
  mounted() {
    this.$root.$on('confirm_dialog', (title, message, confirm) => {
      this.dialog = true
      this.title = title
      this.message = message
      this.resolve = confirm
    })
  },
  methods: {
    async agree() {
      this.isLoading = true
      await this.resolve(true)
      this.isLoading = false

      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    },
  },
}
</script>
