<template>
  <v-app>
    <confirm-dialog />
    <payment-dialog />
    <notification />
    <can-use-test-balance />
    <logout-dialog />

    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-main>
      <!--<v-card-text class="d-flex justify-center pa-4 align-center success">
        <p class="mb-0 text-center text-sm white--text font-weight-bold">
          В настоящее время OZON проводит технические работы, функции нашего сервиса могут быть временно недоступны. Приносим извинения за неудобства.
        </p>
      </v-card-text>-->
      <!--<v-card-text class="font-weight-black grad borderElement d-flex justify-center pa-0 align-center" style="margin-top: 1%">
        <div class="d-flex flex-column align-center">
          <span class="text1"> Успейте воспользоваться. </span>
          <span class="text2"> Бонус к пополнению 20% до 27 января!  </span>
        </div>
      </v-card-text>-->
      <ContentBanner />
      <v-app-bar color="transparent" flat>
        <div class="boxed-container w-full">
          <div class="d-flex align-center mx-6">
            <!-- Left Content -->
            <v-app-bar-nav-icon
              class="d-block d-lg-none me-2"
              @click="isDrawerOpen = !isDrawerOpen"
            ></v-app-bar-nav-icon>
            <p class="text-xl font-weight-semibold">
              {{ routeToName(this.$router.currentRoute['name']) }}
            </p>

            <v-spacer></v-spacer>

            <theme-switcher />
            <app-bar-user-menu></app-bar-user-menu>
          </div>
        </div>
      </v-app-bar>

      <div class="app-content-container pb-5 boxed-container pa-2">

        <div class='text-center'  v-if='$route.meta.isOnTech'>
          <h2>Технические работы</h2>
        </div>
        <slot v-else></slot>
      </div>
    </v-main>

    <v-footer absolute app class="px-0" color="transparent" height="56" inset>
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; 2024
            <a
              class="text-decoration-none"
              href="https://docs.google.com/document/d/16xfryJ8CeI1tf1vonNQyHQSLOhBvrZuQnpyTA3ti0SU/edit"
              target="_blank"
              >Оферта</a
            ></span
          >
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import {
  mdiBellOutline, mdiClose, mdiGithub, mdiMagnify,
} from '@mdi/js'
import ContentBanner from '@/layouts/content-banner/ContentBanner.vue'
import LogoutDialog from '@/layouts/dialogs/LogoutDialog.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import ConfirmDialog from './dialogs/ConfirmDialog.vue'
import PaymentDialog from './dialogs/PaymentDialog.vue'
import Notification from './dialogs/Notification.vue'
import CanUseTestBalance from './dialogs/CanUseTestBalance.vue'

export default {
  components: {
    ContentBanner,
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    ConfirmDialog,
    PaymentDialog,
    Notification,
    CanUseTestBalance,
    LogoutDialog,
  },
  data() {
    return {
      isDrawerOpen: null,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiClose,
      },
    }
  },
  methods: {
    routeToName(route) {
      const routes = {
        deliverys: 'Доставки',
        orders: 'Выкупы',
        orders_create: 'Создание выкупа',
        order: 'Группа выкупов',
        tariffs: 'Тарифы',
        reviews: 'Отзывы',
        finances: 'Финансы',
        favorite_product: 'Товар в избранное',
        'add-to-basket': 'Добавление в корзину',
        providers: 'База поставщиков',
        partner: 'Партнерская система',
        faq: 'Как пользоваться',
        mass_create: 'Массовый выкуп',
        positions: 'Позиции',
        favorite_basket: 'Добавить в корзину',
        profile: 'Настройки профиля',
        review_like: 'Лайки на отзывы',
        likes_create: 'Создание заказа',
        questions: 'Вопросы',
      }

      return routes[route]
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

//.app-content-container {
//  height: 100%;
//}
</style>
