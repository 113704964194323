import Vue from "vue";

export default {
    actions: {
        createBasketTask({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                dispatch('queryPost', { url: 'favorite/add_basket_task', data: data }).then(response => {
                    if(response.data == null){
                        reject(100)
                    }
                    else{
                        resolve(response.data);
                    }
                }).catch(() => {
                    reject(100)
                })
            })
        },

        getBasketTask({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                dispatch('queryPost', { url: 'favorite/basket_tasks', data: data }).then(response => {
                    if(response.data == null){
                        reject(100)
                    }
                    else{
                        resolve(response.data);
                    }
                }).catch(() => {
                    reject(100)
                })
            })
        },
    },
    mutations: {
    },
    state: {
    },
    getters: {
    },
}
