export default {
  actions: {
    getReviewsLikesTasks({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('queryGet', { url: '/reviews_like/get_tasks' })
          .then((response) => {
            if (response.data == null) {
              reject(100)
            } else {
              resolve(response.data)
            }
          })
          .catch(() => {
            reject(100)
          })
      })
    },
    getProductReviews({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', {
          url: '/reviews_like/v2/get_reviews',
          data,
        })
          .then((response) => {
            if (response.data == null) {
              reject(100)
            } else {
              resolve(response.data)
            }
          })
          .catch(() => {
            reject(100)
          })
      })
    },
    createLikesReviewTask({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', {
          url: '/reviews_like/create_task',
          data,
        })
          .then((response) => {
            if (response.data == null) {
              reject(100)
            } else {
              resolve(response.data)
            }
          })
          .catch(() => {
            reject(100)
          })
      })
    },
  },
}
