export default {
  actions: {
    getReviews({ commit, dispatch }, { type, query }) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', { url: '/reviews', data: { type, query } })
          .then((response) => {
            if (response.data == null) {
              reject(100)
            } else {
              resolve(response.data)
            }
          })
          .catch(() => {
            reject(100)
          })
      })
    },
    getReviewDialogInfo({ commit, dispatch }, id) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', { url: '/reviews/info', data: { id } })
          .then((response) => {
            if (response.data == null) {
              reject(100)
            } else {
              resolve(response.data)
            }
          })
          .catch(() => {
            reject(100)
          })
      })
    },

    publishReview({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', { url: '/reviews/publish', data })
          .then((response) => {
            if (response.data == null) {
              reject(100)
            } else {
              resolve(response.data)
            }
          })
          .catch(() => {
            reject(100)
          })
      })
    },

    cancelReview({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        dispatch('queryPost', { url: '/reviews/cancel', data })
          .then((response) => {
            if (response.data == null) {
              reject(100)
            } else {
              resolve(response.data)
            }
          })
          .catch(() => {
            reject(100)
          })
      })
    },

    uploadImage({ commit, dispatch }, image) {
      return new Promise((resolve, reject) => {
        dispatch('uploadFile', { url: '/reviews/upload_image', file: image })
          .then((response) => {
            if (response.data == null) {
              reject(100)
            } else {
              resolve(response.data)
            }
          })
          .catch(() => {
            reject(100)
          })
      })
    },

    uploadVideo({ commit, dispatch }, image) {
      return new Promise((resolve, reject) => {
        dispatch('uploadFile', { url: '/reviews/upload_video', file: image })
          .then((response) => {
            if (response.data == null) {
              reject(100)
            } else {
              resolve(response.data)
            }
          })
          .catch(() => {
            reject(100)
          })
      })
    },
  },
  mutations: {},
  state: {},
  getters: {},
}
