import Vue from 'vue'
import VueRouter from 'vue-router'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import store from '../store/index'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: 'faq',
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/orders',
    name: 'orders',
    meta: {
      authRequired: true,
      isOnTech: false,
    },
    component: () => import('@/views/orders/Orders.vue'),
  },
  {
    path: '/orders/create',
    name: 'orders_create',
    meta: {
      authRequired: true,
      isOnTech: false,

    },
    component: () => import('@/views/orders/CreateOrder.vue'),
  },
  {
    path: '/mass_create',
    name: 'mass_create',
    meta: {
      authRequired: true,
      isOnTech: false,

    },
    component: () => import('@/views/orders/MassOrders/CreateMassOrder.vue'),
  },
  {
    path: '/order/:id',
    name: 'order',
    meta: {
      authRequired: true,
      isOnTech: false,

    },
    props: true,
    component: () => import('@/views/order/OrderView.vue'),
  },
  {
    name: 'service_support',
    path: '/service_support',
    meta: {
      authRequired: true,
    },
    props: true,
    component: () => import('@/views/support_service/change_user.vue'),
  },
  {
    name: 'service_support_promo',
    path: '/service_support_promo',
    meta: {
      authRequired: true,
    },
    props: true,
    component: () => import('@/views/support_service/service_support_promo.vue'),
  },
  {
    path: '/deliverys',
    name: 'deliverys',
    meta: {
      authRequired: true,
    },
    component: () => import('@/views/deliverys/Deliverys.vue'),
  },

  {
    path: '/deliverys/barcode/:id',
    meta: {
      authRequired: false,
      layout: 'blank',
    },
    component: () => import('@/views/deliverys/components/DeliveryBarcode.vue'),
  },

  {
    path: '/tariffs',
    name: 'tariffs',
    meta: {
      authRequired: true,
    },
    component: () => import('@/views/tariffs/Tariffs.vue'),
  },

  {
    path: '/reviews',
    name: 'reviews',
    meta: {
      authRequired: true,
      isOnTech: false,
    },
    component: () => import('@/views/reviews/Reviews.vue'),
  },
  {
    path: '/questions',
    name: 'questions',
    meta: {
      authRequired: true,
      isOnTech: true,

    },
    component: () => import('@/views/questions/QuestionsView.vue'),
  },

  {
    path: '/add-to-basket',
    name: 'add-to-basket',
    meta: {
      authRequired: true,
      isOnTech: true,

    },
    component: () => import('@/views/favorites/add-to-basket/AddToBasketView.vue'),
  },

  {
    path: '/finances',
    name: 'finances',
    meta: {
      authRequired: true,
    },
    component: () => import('@/views/finances/FinancesPage.vue'),
  },
  {
    path: '/providers',
    name: 'providers',
    meta: {
      authRequired: true,
    },
    component: () => import('@/views/finances/Providers.vue'),
  },
  {
    path: '/partner',
    name: 'partner',
    meta: {
      authRequired: true,
    },
    component: () => import('@/views/finances/PartnerSystem.vue'),
  },

  {
    path: '/faq',
    name: 'faq',
    meta: {
      authRequired: true,
    },
    component: () => import('@/views/pages/Faq.vue'),
  },

  {
    path: '/positions',
    name: 'positions',
    meta: {
      authRequired: true,
      isOnTech: true,
    },
    component: () => import('@/views/positions/Positions.vue'),
  },

  {
    path: '/favorite/product',
    name: 'favorite_product',
    meta: {
      authRequired: true,
      isOnTech: true,
    },
    component: () => import('@/views/favorites/ProductLikes.vue'),
  },
  {
    path: '/favorite/reviews/likes',
    name: 'review_like',
    meta: {
      authRequired: true,
      isOnTech: true,

    },
    component: () => import('@/views/favorites/reviews/ReviewsLikes.vue'),
  }, // likes_create
  {
    path: '/favorite/reviews/likes/create',
    name: 'likes_create',
    meta: {
      authRequired: true,
      isOnTech: true,

    },
    component: () => import('@/views/favorites/reviews/ReviewsLikesOrder.vue'),
  }, // likes_create
  {
    path: '/profile',
    name: 'profile',
    meta: {
      authRequired: true,
    },
    component: () => import('@/views/profile/Profile.vue'),
  },

  {
    path: '/success',
    name: 'success',
    meta: {
      authRequired: true,
    },
    component: () => import('@/views/Success.vue'),
  },

  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/views/pages/Auth.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'orders',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    store.dispatch('getUser')
    next()
  } else {
    next()
  }
})

Sentry.init({
  Vue,
  dsn: 'https://753b75fbb9344a009a8b3f273e37e1de@o1189779.ingest.sentry.io/6310677',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'app.ozprod.ru', /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
})

export default router
