


















import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  data() {
    return {
      isOpen: false,
    }
  },

  mounted() {
    this.$root.$on('logout', () => {
      this.isOpen = true
    })
  },

  methods: {
    ...mapActions(['logout']),

    onLogoutClick() {
      this.isOpen = false
      this.logout()
      this.$router.push('/auth')
    },
    onCloseClick() {
      this.isOpen = false
    },
  },
})
