import router from '@/router'
import * as Sentry from '@sentry/vue'

export default {
  actions: {
    queryGet({}, { url, params = {} }) {
      return new Promise((resolve, reject) => {
        this.state.axios.defaults.headers.common['x-api-token'] = localStorage.getItem('token')
        this.state.axios
          .get(url, params)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            try {
              if (error.response.status == 401) {
                router.push('/auth').catch((a = {}))
              }
            } catch (a) {}

            reject(error.response)
          })
      })
    },

    queryPost({}, { url, data, params = {} }) {
      return new Promise((resolve, reject) => {
        this.state.axios.defaults.headers.common['x-api-token'] = localStorage.getItem('token')
        this.state.axios
          .post(url, data, {
            ...params,
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            Sentry.addBreadcrumb({
              category: 'Data',
              message: JSON.stringify(data),
              level: Sentry.Severity.Info,
            })
            try {
              if (error.response.status == 401) {
                router.push('/auth').catch((a = {}))
              }
            } catch (a) {}
            reject(error.response)
          })
      })
    },

    uploadFile({}, { url, file }) {
      return new Promise((resolve, reject) => {
        this.state.axios.defaults.headers.common['x-api-token'] = localStorage.getItem('token')

        const formData = new FormData()

        formData.append('file', file, file.name)
        this.state.axios
          .post(url, formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            try {
              if (error.response.status == 401) {
                router.push('/auth').catch((a = {}))
              }
            } catch (a) {}

            reject(error.response)
          })
      })
    },
  },
}
